import React from 'react';

class FilterNetwork extends React.Component {

    constructor(props) {
        super(props);
        this.state = { 
            icons: {}
        };
    }

    componentDidMount() {
        this.getIcons();
    }

    getIcons() {
        
        // networks to promote in front of the list
        let promote = ['Ethereum','Blast','Polygon','BSC','Optimism','Arbitrum One','zkSync','TON'];

        // icons to remove from object
        const remove = ['default','Coingecko','Coinmarketcap','Discord','Facebook','Github','Instagram','Linkedin','Medium','Reddit','Telegram','Tiktok','Twitter','Web','Youtube'];

        const curnet = this.props.getNetworkFromUrl();

        if (curnet) {

            // get icons in lower case to match url parameter
            const iconsLower = Object.fromEntries(Object.keys(this.props.icons).map(i => {return [i.toLowerCase(),this.props.icons[i]]}));
            const curnetIconLower = Object.fromEntries(Object.entries(iconsLower).filter(item => [curnet].indexOf(item[0]) > -1));
            const curnetIcon = Object.fromEntries(Object.entries(this.props.icons).filter(item => [curnetIconLower[curnet]].indexOf(item[1]) > -1));

            // update promotion with current network
            if(Object.keys(curnetIcon).length) {
                promote = [Object.keys(curnetIcon)[0], ...promote];
            }
        }

        const promoteIcons = Object.fromEntries(Object.keys(promote).map((v) => (this.props.icons[promote[v]]) ? [promote[v],this.props.icons[promote[v]]] : [v,promote[v]] ));

        const leftIcons = Object.fromEntries(Object.entries(this.props.icons).filter(item => [...promote,...remove].indexOf(item[0]) === -1));

        this.setState({
            icons: {
                ...promoteIcons,
                ...leftIcons
            }
        });

        // expand networks list if there is a current network
        // if(Object.keys(leftIcons).map(n => {return n.toLowerCase()}).indexOf(curnet) > -1) {
        //     this.props.moreFilters(Object.entries(this.props.icons).length);
        // }
        
    }

    render() {

        
        return (
            <div className="filter-nets">
                <label className="filter-tag first">
                <input type="radio" name="category" value="all" onClick={() => this.props.filterByNet()} onChange={e => {}} checked={(!this.props.curnet)} /><span className="btn btn-gray">All</span>
                </label>
                { Object.entries(this.state.icons).slice(0,this.props.loadnets).map(([key,val],id) => (
                    <label className="filter-tag" key={id}>
                    <input type="radio" name="category" value={key} onClick={() => this.props.filterByNet(key)} onChange={e => {}} checked={(this.props.curnet === key.toLowerCase())} /><span className="btn btn-gray"><i className={"svg-"+key.replace(" ","-").replace(".","-").toLowerCase()} title={key}>{key}</i>  {key.replace("Arbitrum One","Arbitrum")}</span>
                    </label>
                    ))
                }
                { ( Object.entries(this.state.icons).length > this.props.loadnets ) ? (
                    <label className="filter-tag last">
                    <input type="radio" name="morefilters" value="+" onClick={(e) => { e.preventDefault(); this.props.moreFilters(this.props.loadnets + 22);}} onChange={e => {}} /><span className="btn btn-gray">+</span>
                    </label>
                ) : null }
            </div>
        );
    }
}

export default FilterNetwork;